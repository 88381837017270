import React, { FC } from 'react';
import clsx from 'clsx';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './FormContainer.module.scss';

interface FormContainerProps {
  title: string;
  subtitle?: string;
  className?: string;
}

const FormContainer: FC<FormContainerProps> = ({ children, title, subtitle, className }) => {
  const { themeStyles } = useLayoutTheme();

  return (
    <div className={clsx(styles.container, className, themeStyles.formContainer)}>
      <div>
        <h1 className={styles.title}>{title}</h1>
        {subtitle && <p className={clsx(styles.subtitle, themeStyles.subtitle)}>{subtitle}</p>}
      </div>
      {children}
    </div>
  );
};

export default FormContainer;
