import React from 'react';

import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import clsx from 'clsx';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './CardApplicationSuccessful.module.scss';

const CardApplicationSuccessful = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const { themeStyles } = useLayoutTheme();

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <div className={clsx(styles.container, themeStyles.formContainer)}>
        <div className={styles.iconContainer}>
          <CheckIcon className={themeStyles.icon} />
        </div>
        <div className={clsx(styles.title, themeStyles.title)}>Thanks for applying!</div>
        <div className={clsx(styles.description, themeStyles.text)}>
          Next, we’ll set up the features on your account.
        </div>
        <Button type={ButtonType.Primary} onClick={handleNext}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default CardApplicationSuccessful;
