import { RoutePath } from 'enums/Routes';

export const cardRoutes = [
  RoutePath.YourName,
  RoutePath.YourPhoneNumber,
  RoutePath.YourEmail,
  RoutePath.MethodAuth,
  RoutePath.Analyzing,
  RoutePath.CardApplied,
];
