import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useAutoScroll from 'hooks/useAutoScroll';
import useQueryParam, { useQueryParams } from 'hooks/useQueryParam';
import { useNavigate } from 'hooks/useNavigate';

import { setApplicationFlow, setApplicationStep } from 'handlers/applicationStep';
import { PreQualificationData, setAssociationBranding, setGoal } from 'handlers/preQualificationData';
import { setCardData } from 'handlers/cardData';
import { setReferralApplicationId } from 'handlers/referrals';

import { getApplicationData, getUserLocation } from 'thunks';
import { getState } from 'selectors/getState';
import { getApplicationData as applicationData } from 'selectors/getApplicationData';
import { getApplicationStep } from 'selectors/getApplicationStep';

import { RoutePath } from 'enums/Routes';
import { CurrentFlow } from 'enums/CurrentFlow';
import { AssociationBrandingName } from 'enums/AssociationBrandingName';
import { LoanGoalName } from 'enums/LoanGoalName';
import { UtmCampaign, UtmSource } from 'enums/UtmTagName';

import Root from 'pages/Root';

import PageLoader from 'components/PageLoader';

import FlowRouter, { CustomRouterType } from './FlowRouter';

const FLOW_ARG_TO_CURRENT_FLOW: Record<string, CurrentFlow> = {
  checkup: CurrentFlow.FinancialCheckup,
  card: CurrentFlow.Card,
};

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useAutoScroll();

  const state = useSelector(getState);
  const { stepName, currentFlow } = getApplicationStep(state);
  const { application, isLoading: isLoadingApplicaiton } = applicationData(state);

  const flags = useFlags();
  const params = useQueryParams();
  const isResumeProcess = params.has('resume-process');
  const flow = useQueryParam('flow');
  const applicationId = useQueryParam('resume-process');

  const utmSource = useQueryParam('utm_source');
  const utmContent = useQueryParam('utm_content');
  const utmCampaign = useQueryParam('utm_campaign');
  const initialReferrer = useQueryParam('initialReferrer');

  const getExistingPath = (path: string): RoutePath | undefined =>
    Object.values(RoutePath).find((existingPath) => existingPath === path);

  const handleInitialQueryParams = () => {
    const associationParam = searchParams.get('association');
    const existingAssociation = Object.values(AssociationBrandingName).find(
      (association) => association === associationParam,
    );
    if (existingAssociation) {
      const preQualificationData: PreQualificationData = {
        association_branding: existingAssociation!,
      };
      dispatch(setAssociationBranding(preQualificationData));
    }

    const goalParam = searchParams.get('goal');
    if (goalParam) {
      const existingGoal = Object.entries(LoanGoalName).find(([key]) => key === goalParam)?.[1];
      const goal = existingGoal || goalParam;
      dispatch(setGoal(goal));
    }

    if (utmSource === UtmSource.Referral) {
      const referrer = utmContent || '';
      const isCardReferral = utmCampaign === UtmCampaign.PlanneryCard;
      if (isCardReferral) {
        dispatch(
          setCardData({
            referredBy: referrer,
            initialReferrer: initialReferrer || '',
          }),
        );
      } else {
        dispatch(setReferralApplicationId(referrer));
      }
    }
  };

  useEffect(() => {
    analytics.identify({ flags });

    if (stepName === undefined && getExistingPath(location.pathname) && currentFlow === undefined) {
      // Backwards compatibility for old applications
      dispatch(setApplicationFlow({ currentFlow: CurrentFlow.V1, stepName: getExistingPath(location.pathname) }));
    }

    // If there is a resume process
    if (isResumeProcess && !application && !isLoadingApplicaiton) {
      dispatchWithUnwrap(getApplicationData(applicationId!)).then((response) => {
        analytics.identify(response.application.borrowerId);
        (window as any).nid('setUserId', response.application.id);
      });
    }

    // if there is no step data and resume process, set flow and stepname
    if (!isResumeProcess && !stepName && !getExistingPath(location.pathname)) {
      dispatch(setApplicationFlow({ currentFlow: FLOW_ARG_TO_CURRENT_FLOW[flow!] || CurrentFlow.V1 }));
    }

    const trackUserLocation = async () => {
      const userLocation = await dispatchWithUnwrap(getUserLocation());
      if (userLocation?.clientCountryRegion) {
        analytics.identify({
          clientCountry: userLocation.clientCountryRegion.substr(0, 2),
          clientRegion: userLocation.clientCountryRegion.substr(2),
          clientCity: userLocation.clientCity,
        });
      }
    };

    handleInitialQueryParams();
    trackUserLocation();
  }, []);

  useEffect(() => {
    if (stepName && !getExistingPath(location.pathname) && stepName !== location.pathname) {
      navigate(stepName, { replace: true });
    }
  }, [stepName]);

  useEffect(() => {
    if (location) {
      const actualPath = getExistingPath(location.pathname);
      if (actualPath) {
        dispatch(setApplicationStep(actualPath));
      }
    }
  }, [location]);

  if (isResumeProcess && isLoadingApplicaiton && !application) {
    return (
      <Routes>
        <Route path="*" element={<PageLoader />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Root />}>
        {currentFlow !== undefined &&
          Object.keys(FlowRouter[currentFlow]).map((path) => {
            const { component: Component, navigationInfo, handleNext } = FlowRouter[currentFlow][
              path as RoutePath
            ] as CustomRouterType;
            return (
              <Route
                path={path}
                element={
                  <Component
                    flags={flags}
                    navigationInfo={navigationInfo || {}}
                    handleNext={
                      handleNext ? handleNext({ state, navigate, flags, dispatch, dispatchWithUnwrap }) : () => {}
                    }
                  />
                }
                key={path}
              />
            );
          })}
      </Route>
    </Routes>
  );
};
